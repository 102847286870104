import * as React from "react"
import { FC, useContext } from "react"
import styled from "styled-components"
import Tile from "../../components/dashboard/Tile"
import {
  LoginContext,
  LoginFormWrapper,
  Spinner,
} from "@social-supermarket/social-supermarket-components"
import GLink from "../../../gatsby/GLink"
import TeaserBackground from "../../components/TeaserBackground"
import { colors } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FormContainer = styled(Tile)`
  width: 100%;
  max-width: 500px;
  z-index: 1;
`

const Links = styled.div`
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
`
const Error = styled.div`
  margin-top: 20px;
  color: ${colors.rouge};
  font-size: 0.8em;
`

interface Props {}

const LoginPage: FC<Props> = () => {
  const loginContext = useContext(LoginContext)
  return (
    <Container>
      <Spinner isLoading={loginContext.loggingIn} label={"Logging In.."} />
      <TeaserBackground />
      <FormContainer>
        <LoginFormWrapper />
        <Links>
          <div>
            <GLink to={"/register"}>Register</GLink>
          </div>
          <div>
            {" "}
            <GLink to={"/password-reset"}>Reset Password</GLink>
          </div>
        </Links>
        {loginContext.error && <Error>{loginContext.error}</Error>}
      </FormContainer>
    </Container>
  )
}

export default LoginPage
