import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import FaqAccordion from "../../components/FaqAccordion"

const Container = styled.div`
  padding: 0 10px;
  padding-bottom: 40px;
`
const ContentContainer = styled.div`
  width: calc(100%);
  background-color: white;
  margin-top: 10px;
  padding: 20px;
`
const Heading = styled.h1``

interface Props {}

const GuidePage: FC<Props> = () => {
  return (
    <Container>
      <ContentContainer>
        <Heading>What is This?</Heading>
        <p>
          Social Supermarket's corporate platform is an easy way to send gifts and buy supplies for
          your office, where we also track the social impact of your purchases.
        </p>
      </ContentContainer>

      <ContentContainer>
        <Heading>How to send a gift to multiple recipients</Heading>
        <p>
          <ol>
            <li>Go to the gifting section</li>
            <li>
              Find the gift you would like to send using the category and price range filters.
            </li>
            <li>On the product you want to send click the "..." -> "Order Now" -> "Checkout"</li>
            <li>
              Proceed through the checkout process, and notice you can add as many recipients as you like in step 2.
              Each recipient will receive the gift you have selected,
              there is no need to add multiple products to your basket.
              Add one product and you will be charged based on the price of the product and the number of recipients you add.
            </li>
            <li>Pay for the gifts via credit card. If you would prefer to pay via invoice please let us know and we will enable this for you.</li>
          </ol>
        </p>
      </ContentContainer>
      <ContentContainer>
        <Heading>How to let the recipients choose their own gift</Heading>
        <p>
          <ol>
            <li>Go to the gifting section</li>
            <li>
              Find some gifts within your budget for recipients to choose from and click "Add To
              Gift Page" to start building your gift list.
            </li>
            <li>Click the "Create Page" button.</li>
            <li>
              Confirm the product selections and design your page by providing a title and
              description.
            </li>
            <li>Invite recipients to claim their gift via email or a unique public link.</li>
            <li>They will choose which gift they would like and enter their delivery details.</li>
            <li>Bulk approve and pay for the responses.</li>
            <li>
              When a response is approved and paid for we will get it shipped to the recipient.
            </li>
          </ol>
        </p>
      </ContentContainer>

      <ContentContainer>
        <Heading>Gift Page FAQ</Heading>
        <FaqAccordion />
      </ContentContainer>

      <ContentContainer>
        <Heading>Get In Touch</Heading>
        <p>If you have any questions please get in touch at support@socialsupermarket.org</p>
      </ContentContainer>
    </Container>
  )
}

export default GuidePage
