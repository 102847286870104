import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import GImage from "../../gatsby/GImage"

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(100vh - 50px);
  top: 50px;
  left: 0;
  z-index: 0;
`
const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(100, 100, 100, 0.5);
  position: absolute;
  top: 0;
  left: 0;
`
const Gmage = styled(GImage)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

interface Props {}

const TeaserBackground: FC<Props> = () => {
  return (
    <Container>
      <Overlay />
      <Gmage
        image={{
          src: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Screenshot-2022-09-27-at-19.26.13-scaled.jpg",
          alt: "background",
        }}
      />
    </Container>
  )
}

export default TeaserBackground
