import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import Accordion from "./accordion/Accordion"
import AccordionItem from "./accordion/AccordionItem"
import AccordionHeader from "./accordion/AccordionHeader"
import AccordionBody from "./accordion/AccordionBody"

const Container = styled.div``
const P = styled.p`
  font-size: 0.9em;
  padding: 10px;
  padding-top: 5px;
`

interface Props {}

const FaqAccordion: FC<Props> = () => {
  return (
    <Container>
      <Accordion>
        <AccordionItem>
          <AccordionHeader>I know the address, can I just send a gift?</AccordionHeader>
          <AccordionBody>
            <P>
              Yes! In the gifting catalogue on the product you want to send click the "..." and
              select "Order Now".
            </P>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader>
            I don’t want to share my team’s email addresses, can I still use the Gift Pages to let
            them choose?
          </AccordionHeader>
          <AccordionBody>
            <P>
              No problem, on the order page you can enable a "Public Selection Page" which is a
              unique link you can share internally, bypassing the need to provide any email
              addresses.
            </P>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader>Is the claim link unique to each recipient?</AccordionHeader>
          <AccordionBody>
            <P>
              When inviting recipients via email, each recipient will be provided with a unique
              link. This means only they can submit their response, and only once.
            </P>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader>What if someone who’s not in my team submits a choice? </AccordionHeader>
          <AccordionBody>
            <P>
              You approve all responses before payment. So, if for any reason your gift selection
              link is shared with someone that isn’t on your gift page and they submit a choice, you
              can simply remove it.
            </P>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader>Are all Social Supermarket products on the Platform?</AccordionHeader>
          <AccordionBody>
            <P>
              Not all products that you find on Social Supermarket are included in the Platform. The
              Platform contains a curated range of our most giftable products from our most trusted
              and popular brands. If you’ve spotted a product that you would like to send that isn’t
              available in the Platform, please get in touch and we will see what we can do.
            </P>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </Container>
  )
}

export default FaqAccordion
